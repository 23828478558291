import { z } from 'zod';

const DOCUMENTS_RESPONSE_SCHEMA = z.object({
  name: z.string(),
  created: z.string(),
  version: z.number(),
});

const METADATA = z.object({
  filename: z.string().optional(),
  size: z.number().optional(),
});

export const DOCUMENT_RESPONSE_SCHEMA = z.object({
  name: z.string(),
  metadata: METADATA.optional(),
});

export const COLLECTION_RESPONSE_SCHEMA = z.object({
  collection: z.string(),
  documents: z.array(DOCUMENTS_RESPONSE_SCHEMA).optional(),
});

export const PROVIDED_COLLECTION_RESPONSE_SCHEMA = z.array(COLLECTION_RESPONSE_SCHEMA);

export const MIGRATE_COLLECTION_TO_WORKBOOK_RESPONSE = z.object({
  workbook_id: z.number(),
});

export type CollectionResponse = z.infer<typeof COLLECTION_RESPONSE_SCHEMA>;
export type DocumentResponse = z.infer<typeof DOCUMENT_RESPONSE_SCHEMA>;
export type ProvidedCollectionResponse = z.infer<typeof PROVIDED_COLLECTION_RESPONSE_SCHEMA>;
export type MigrateCollectionToWorkbookResponse = z.infer<
  typeof MIGRATE_COLLECTION_TO_WORKBOOK_RESPONSE
>;
