import { LANGUAGE_MODEL_RESOURCE_SCHEMA } from '@/@core/models/api/resourcesResponse';
import { InstructionPreset } from '@/@core/models/instructionPresets';
import { Locale } from '@/stores/useWorkspaceStore';
import { z } from 'zod';

export const AVAILABLE_SUMMARY_LENGTHS_SCHEMA = z.enum(['short', 'medium', 'long']);
export type AvailableSummaryLengths = z.infer<typeof AVAILABLE_SUMMARY_LENGTHS_SCHEMA>;

export interface SummaryTextRequest {
  text: string;
  fallback_language: Locale;
  workbook_id?: number;
  response_depth?: string;
  language_style?: string;
}

export interface SummaryDocumentRequest {
  file: File;
  fallback_language: Locale;
  workbook_id?: number;
}

export function isSummaryTextRequest(
  request: SummaryTextRequest | SummaryDocumentRequest
): request is SummaryTextRequest {
  return (request as SummaryTextRequest).text !== undefined;
}

export const SUMMARY_RESPONSE_SCHEMA = z.object({
  summary: z.string(),
  trace_id: z.string(),
  safe_output: z.boolean(),
});

export type SummaryResponse = z.infer<typeof SUMMARY_RESPONSE_SCHEMA>;

export const SUMMARY_RESOURCES_RESPONSE_SCHEMA = z.array(LANGUAGE_MODEL_RESOURCE_SCHEMA);
export type SummaryResourcesResponse = z.infer<typeof SUMMARY_RESOURCES_RESPONSE_SCHEMA>;
