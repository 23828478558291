import { CollectionResource, UserCollectionResource } from './resourcesResponse';
import { Locale } from '@/stores/useWorkspaceStore';
import { z } from 'zod';

export interface SearchRequest {
  question: string;
  collections: Array<CollectionResource | UserCollectionResource>;
  fallback_language?: Locale;
}

export const SOURCES_SCHEMA = z.object({
  title: z.string(),
  text: z.string(),
  link: z.string(),
  date: z.string(),
  score: z.number(),
});
export type SearchResponseSource = z.infer<typeof SOURCES_SCHEMA>;

export const SEARCH_RESPONSE_SCHEMA = z.object({
  answer: z.string().optional(),
  sources: z.array(SOURCES_SCHEMA),
  trace_id: z.string(),
  safe_output: z.boolean(),
});
export type SearchResponse = z.infer<typeof SEARCH_RESPONSE_SCHEMA>;
