import { alternateHomeRoute } from '@/modules/AlternateHome/route';
import { qaRoute } from '@/modules/Qa/route';
import { useTranslationResourcesStore } from '@/modules/Translation/stores/useTranslationResourcesStore';
import { useTranscriptionStore } from '@/modules/transcription/stores/useTranscriptionStore';
import { useConfigStore } from '@/stores/useConfigStore';
import { NavigationGuard } from 'vue-router';

export async function withTranslationResources() {
  await useTranslationResourcesStore().getTranslationResources();
}

export async function withTranscriptionResources() {
  await useTranscriptionStore().initialize();
}

export async function withConfig() {
  const configStore = useConfigStore();
  if (!configStore.config) {
    await configStore.getConfig();
  }
}

export function redirectToQaRouteIfHomepageDisabled(homepageIsDisabled: boolean): NavigationGuard {
  return (to) => {
    if (to.path === alternateHomeRoute.path && homepageIsDisabled) {
      return { path: qaRoute.path };
    }
  };
}
