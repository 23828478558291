import { AUTH_CALLBACK_GUARD, AUTH_GUARD } from '@/@core/services/auth/auth';
import { FeatureFlagClient } from '@/@core/services/feature-flag/FeatureFlagClient';
import { getEnabledFeatureRoutes } from '@/@core/utils/enabledFeatures';
import { alternateHomeRoute } from '@/modules/AlternateHome/route';
import { redirectToQaRouteIfHomepageDisabled, withConfig } from '@/router/navigationGuards';
import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';

// Extend RouteMeta interface for TypeScript
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
  }
}

export function initializeRouterWithFeatureFlags(featureFlagClient: FeatureFlagClient): Router {
  const rootRoute: RouteRecordRaw = {
    path: '/',
    name: 'App',
    component: () => import('@/@core/views/BaseView.vue'),
    children: [alternateHomeRoute],
    beforeEnter: [
      withConfig,
      redirectToQaRouteIfHomepageDisabled(
        !featureFlagClient.getFlag('enableHomepage') &&
          !featureFlagClient.getFlag('enableAlternateHome')
      ),
    ],
    meta: {
      requiresAuth: true,
    },
  };
  const loginCallbackRoute = {
    path: '/callback',
    name: 'LoginCallback',
    beforeEnter: AUTH_CALLBACK_GUARD,
  } as RouteRecordRaw;

  const cockpitRedirectRoute: RouteRecordRaw = {
    path: '/cockpit',
    redirect: '/',
  };

  const notFoundRoute: RouteRecordRaw = {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('@/@core/views/NotFound.vue'),
  };
  const routes: RouteRecordRaw[] = [
    rootRoute,
    loginCallbackRoute,
    cockpitRedirectRoute,
    notFoundRoute,
  ];

  const enabledFeatureRoutes = getEnabledFeatureRoutes(featureFlagClient);
  enabledFeatureRoutes.forEach((route) => rootRoute.children.push(route));

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  router.beforeEach(AUTH_GUARD);

  return router;
}
