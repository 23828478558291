import { z } from 'zod';

const LOCALIZED_PRIVACY_POLICY_NOTICE_RESPONSE_SCHEMA = z.object({
  locale: z.string(),
  text: z.string(),
  link_url: z.string().url(),
  link_title: z.string(),
});
export type LocalizedPrivacyPolicyNoticeResponse = z.infer<
  typeof LOCALIZED_PRIVACY_POLICY_NOTICE_RESPONSE_SCHEMA
>;

export const PRIVACY_POLICY_NOTICE_RESPONSE_SCHEMA = z.object({
  localizations: z.array(LOCALIZED_PRIVACY_POLICY_NOTICE_RESPONSE_SCHEMA),
});
export type PrivacyPolicyNoticeResponse = z.infer<typeof PRIVACY_POLICY_NOTICE_RESPONSE_SCHEMA>;
