import { StateTree } from 'pinia';
import { Serializer } from 'pinia-plugin-persistedstate';

type CustomSerializer = {
  serialize: (value: any) => any;
  deserialize: (value: any) => StateTree;
};

type CustomSerializerMap = Record<string, CustomSerializer>;

export function createPiniaStateSerializer(customSerializers: CustomSerializerMap): Serializer {
  return {
    serialize(object: any): string {
      return JSON.stringify(object, (currentKey, value) => {
        return customSerializers[currentKey]
          ? customSerializers[currentKey].serialize(value)
          : value;
      });
    },
    deserialize(data: string): StateTree {
      return JSON.parse(data, (currentKey, value) => {
        return customSerializers[currentKey]
          ? customSerializers[currentKey].deserialize(value)
          : value;
      });
    },
  };
}

export const dateSerializer: CustomSerializer = {
  serialize: (value: string) => new Date(value).toISOString(),
  deserialize: (value) => new Date(value),
};

type SerializedFile = {
  name: string;
  size: number;
  type: string;
};

export const fileSerializer: CustomSerializer = {
  serialize: (value: File | undefined): SerializedFile | undefined => {
    return value === undefined
      ? undefined
      : {
          name: value.name,
          size: value.size,
          type: value.type,
        };
  },
  deserialize: (value: SerializedFile): File =>
    new File([new Uint8Array(value.size)], value.name, { type: value.type }),
};
