import { sidebarIconClasses } from '@/@core/components/Sidebar/classes';
import { FeatureFlagClient } from '@/@core/services/feature-flag/FeatureFlagClient';
import { SidebarItem, UseCaseItem } from '@/@core/types/useCases';
import { alternateHomeRoute } from '@/modules/AlternateHome/route';
import { chatRoute } from '@/modules/Chat/route';
import { homepageRoute } from '@/modules/Homepage/route';
import { knowledgeBasesRoute } from '@/modules/KnowledgeBases/route';
import { poetryRoute } from '@/modules/Poetry/route';
import { qaRoute } from '@/modules/Qa/route';
import { searchRoute } from '@/modules/Search/route';
import { summaryRoute } from '@/modules/Summary/route';
import { tellJokeRoute } from '@/modules/TellJoke/route';
import { translationRoute } from '@/modules/Translation/route';
import { travelCompanionRoute } from '@/modules/TravelCompanion/route';
import { useCaseRoute } from '@/modules/UseCase/route';
import { workbooksRoute } from '@/modules/Workbooks/route';
import { generationRoute } from '@/modules/generation/route';
import { transcriptionRoute } from '@/modules/transcription/route';
import { RouteRecordRaw } from 'vue-router';

export function getEnabledBuiltInSkillUseCaseItems(
  featureFlagClient: FeatureFlagClient
): UseCaseItem[] {
  // Note that the item order is intentional !!!
  const builtInUseCaseItems = [
    {
      titleLocalizationKey: 'SKILLS.qa',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.qa',
      route: qaRoute,
      displayCondition: () => featureFlagClient.getFlag('enableQa'),
      icon: `i-material-symbols-sms-outline ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.summarize',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.summarize',
      route: summaryRoute,
      displayCondition: () => featureFlagClient.getFlag('enableSummarize'),
      icon: `i-material-symbols-article-outline ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.translate',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.translate',
      route: translationRoute,
      displayCondition: () => featureFlagClient.getFlag('enableTranslate'),
      icon: `i-material-symbols-translate ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.search',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.search',
      route: searchRoute,
      displayCondition: () => featureFlagClient.getFlag('enableSearch'),
      icon: `i-material-symbols-search ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.chat',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.chat',
      route: chatRoute,
      displayCondition: () => featureFlagClient.getFlag('enableChat'),
      icon: `i-material-symbols-chat-outline ${sidebarIconClasses}`,
    },

    {
      titleLocalizationKey: 'SKILLS.transcribe',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.transcribe',
      route: transcriptionRoute,
      displayCondition: () => featureFlagClient.getFlag('enableTranscribe'),
      icon: `i-material-symbols-voice-chat-outline ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.generate',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.generate',
      route: generationRoute,
      displayCondition: () => featureFlagClient.getFlag('enableGenerate'),
      icon: `i-material-symbols-edit-note ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.tellJoke',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.tellJoke',
      route: tellJokeRoute,
      displayCondition: () => featureFlagClient.getFlag('enableTellJoke'),
      icon: `i-material-symbols-sentiment-very-satisfied-outline ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.poetry',
      descriptionLocalizationKey: 'SKILLS.poetry',
      route: poetryRoute,
      icon: `i-material-symbols:auto-stories-outline ${sidebarIconClasses}`,
      displayCondition: () => featureFlagClient.getFlag('enablePoetry'),
    },
    {
      titleLocalizationKey: 'SKILLS.travelCompanion',
      descriptionLocalizationKey: 'SKILLS-DESCRIPTION.travelCompanion',
      route: travelCompanionRoute,
      displayCondition: () => featureFlagClient.getFlag('enableTravelCompanion'),
      icon: `i-material-symbols-airplane-ticket-outline ${sidebarIconClasses}`,
    },
  ];
  return builtInUseCaseItems.filter((v) => v.displayCondition && v.displayCondition());
}

export function getResourcesSidebarItems(): SidebarItem[] {
  return [
    {
      titleLocalizationKey: 'SKILLS.knowledge-bases',
      route: knowledgeBasesRoute,
      icon: `i-material-symbols-book-2-outline-rounded ${sidebarIconClasses}`,
    },
    {
      titleLocalizationKey: 'SKILLS.workbooks',
      route: workbooksRoute,
      icon: `i-material-symbols-note-stack-outline-rounded ${sidebarIconClasses}`,
    },
  ];
}

export function getHomepageSidebarItem(featureFlagClient: FeatureFlagClient): SidebarItem | null {
  const displayHomepage = featureFlagClient.getFlag('enableHomepage');
  const displayAlternateHomepage = featureFlagClient.getFlag('enableAlternateHome');

  if (displayHomepage) {
    return {
      titleLocalizationKey: 'SKILLS.home',
      route: homepageRoute,
      icon: `i-material-symbols-home-outline-rounded ${sidebarIconClasses}`,
      displayCondition: () => featureFlagClient.getFlag('enableHomepage'),
    };
  } else if (displayAlternateHomepage) {
    return {
      titleLocalizationKey: 'SKILLS.alternateHome',
      route: alternateHomeRoute,
      icon: `i-material-symbols-home-outline ${sidebarIconClasses}`,
      displayCondition: () => featureFlagClient.getFlag('enableAlternateHome'),
    };
  }
  return null;
}

export function getEnabledFeatureRoutes(featureFlagClient: FeatureFlagClient): RouteRecordRaw[] {
  const homepageSidebarItem = getHomepageSidebarItem(featureFlagClient);

  return [
    ...(homepageSidebarItem ? [homepageSidebarItem] : []),
    ...getResourcesSidebarItems(),
    ...getEnabledBuiltInSkillUseCaseItems(featureFlagClient),
    {
      route: useCaseRoute,
      displayCondition: () => featureFlagClient.internalReleaseFeaturesEnabled(),
    },
  ]
    .filter(({ displayCondition }) => !displayCondition || displayCondition())
    .map(({ route }) => route);
}
