import { z } from 'zod';

export const FEATURE_FLAGS_SCHEMA = z.object({
  enableHomepage: z.boolean().optional(),
  enableSummarize: z.boolean().optional(),
  enableQa: z.boolean().optional(),
  enableTranslate: z.boolean().optional(),
  enableTranscribe: z.boolean().optional(),
  enableGenerate: z.boolean().optional(),
  enableSearch: z.boolean().optional(),
  enableAlternateHome: z.boolean().optional(),
  enableTellJoke: z.boolean().optional(),
  enableTravelCompanion: z.boolean().optional(),
  enableChat: z.boolean().optional(),
  enableInDevelopmentFeatures: z.boolean().optional(),
  enableInternalReleaseFeatures: z.boolean().optional(),
  enableInstructionPresets: z.boolean().optional(),
  enableBetaFeatures: z.boolean().optional(),
  enablePoetry: z.boolean().optional(),
});
export type FeatureFlags = z.infer<typeof FEATURE_FLAGS_SCHEMA>;

export type FeatureFlag = keyof FeatureFlags;
