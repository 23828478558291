import App from './App.vue';
import i18n from './i18n';
import { piniaPluginErrorNotifier, piniaPluginLoadingState } from './plugins';
import { initializeRouterWithFeatureFlags } from './router/initializeRouterWithFeatureFlags';
import { ASSISTANT_SERVICE } from '@/@core/services/api/AssistantService';
import { FEATURE_FLAG_CLIENT } from '@/@core/services/feature-flag/FeatureFlagClient';
import { hashString } from '@/@core/utils';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { useUserStore } from '@/stores/userStore';
import AADesignSystem from '@aleph-alpha/ds-components-vue';
import '@unocss/reset/tailwind.css';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import 'virtual:uno.css';
import { createApp } from 'vue';

export const app = createApp(App);

app.use(i18n);

// @ts-expect-error AADesignSystem is a plugin, but it's not typed as such in the package.
app.use(AADesignSystem);

const pinia = createPinia();
console.log('App Started');
pinia.use(
  createPersistedState({
    key: (id) => {
      const userStore = useUserStore();
      let prefix = hashString('assistantuicache', true);
      if (userStore.user !== null) {
        prefix = hashString(userStore.user.profile.email, true);
      }
      return `${prefix}-${id}`;
    },
  })
);
pinia.use(piniaPluginLoadingState);
pinia.use(piniaPluginErrorNotifier(useNotificationStore, i18n.global));
app.use(pinia);

FEATURE_FLAG_CLIENT.setFlags(await ASSISTANT_SERVICE.featureFlags());
const router = initializeRouterWithFeatureFlags(FEATURE_FLAG_CLIENT);
app.use(router);

app.mount('#app');
