import { ApiError, InputIsTooShortError, RequestAbortError } from '@/@core/models/api/errors';
import { Response } from '@aleph-alpha/lib-http';
import { HTTPError, KyResponse } from 'ky';

// TODO: adapt types after moving to ky only
export async function handleRequestError<R, T extends KyResponse<R> | Response<R>>(
  makeRequestCallback: () => Promise<T>
): Promise<T> {
  try {
    return await makeRequestCallback();
  } catch (error: unknown) {
    if (isAbortError(error)) {
      throw new RequestAbortError();
    }
    if (await isInputIsTooShortError(error)) {
      throw new InputIsTooShortError();
    }
    if (error instanceof HTTPError) {
      throw new ApiError(error.message, { cause: error.cause });
    } else if (
      error &&
      typeof error === 'object' &&
      'constructor' in error &&
      error.constructor.name === 'HTTPError' &&
      'message' in error &&
      typeof error.message === 'string'
    ) {
      // Error is an instance of HTTPError, but not of the correct class. This is a workaround that may be removed when
      // upstream contains a solution for this issue.
      throw new ApiError(error.message);
    }
    throw new ApiError();
  }
}

function isAbortError(error: unknown): boolean {
  return (
    typeof error === 'object' && error !== null && 'name' in error && error.name === 'AbortError'
  );
}

async function isInputIsTooShortError(error: unknown): Promise<boolean> {
  try {
    const errorResponse = await (error as Error)['response'].json();
    return 'detail' in errorResponse && errorResponse.detail === 'INPUT_TOO_SHORT';
  } catch {
    return false;
  }
}
