import { FeatureFlag, FeatureFlags } from '@/@core/models/FeatureFlags';

export class FeatureFlagClient {
  constructor(private flags: Partial<FeatureFlags>) {}

  public setFlags(flags: Partial<FeatureFlags>): void {
    this.flags = flags;
  }

  public getFlag(flagKey: FeatureFlag): boolean {
    const flagValue = this.getFlagOrDefault(flagKey, false);
    switch (flagKey) {
      case 'enableTranscribe':
      case 'enableGenerate':
        return this.internalReleaseFeaturesEnabled() && flagValue;
      default:
        return flagValue;
    }
  }

  public inDevelopmentFeaturesEnabled(): boolean {
    return this.getFlag('enableInDevelopmentFeatures') && this.getFlag('enableBetaFeatures');
  }

  public internalReleaseFeaturesEnabled(): boolean {
    return this.getFlag('enableInternalReleaseFeatures');
  }

  private getFlagOrDefault(flagKey: FeatureFlag, defaultValue: boolean = false): boolean {
    return this.flags[flagKey] ?? defaultValue;
  }
}

export const FEATURE_FLAG_CLIENT = new FeatureFlagClient({});
