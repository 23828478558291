import { authManager } from '@/@core/services/auth/auth';
import { useUserStore } from '@/stores/userStore';
import { http } from '@aleph-alpha/lib-http';
import ky from 'ky';

const kyBeforeRequestValidateTokenInterceptor = async (request: Request) => {
  const userStore = useUserStore();
  if (request.headers.has('authorization') && userStore.isInvalidUserSession()) {
    await authManager.signinRedirect();
  }
  return request;
};

export const HTTP_CLIENT = http({
  baseURL: '/api',
  retry: {
    limit: 5,
    methods: ['get'],
    statusCodes: [404],
  },
  hooks: {
    beforeRequest: [kyBeforeRequestValidateTokenInterceptor],
  },
});

// TODO: AST-668
export const KY_HTTP_CLIENT = ky.create({
  hooks: { beforeRequest: [kyBeforeRequestValidateTokenInterceptor] },
});
