class CustomError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
  }
}

export class ApiError extends CustomError {
  constructor(message?: string, options?: ErrorOptions) {
    super(message ? `API request failed: ${message}` : 'API request failed', options);
  }
}

export class ResponseValidationError extends CustomError {
  constructor(message?: string, options?: ErrorOptions) {
    super(
      message ? `Response validation failed: ${message}` : 'Response validation failed',
      options
    );
  }
}

export class RequestAbortError extends CustomError {
  constructor(message?: string, options?: ErrorOptions) {
    super(message ? `Request aborted: ${message}` : 'Request aborted', options);
  }
}

export class InputIsTooShortError extends CustomError {
  constructor() {
    super(`Input error: input is too short`, { cause: 'INPUT_TOO_SHORT' });
  }
}
