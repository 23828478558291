import {
  Awaitable,
  MaybeRefOrGetter,
  Pausable,
  useTimeoutPoll,
  UseTimeoutPollOptions,
} from '@vueuse/core';

/**
 Extension of vueuse's useTimeoutPoll that stops automatically after a defined expiry time.

 @param fn The polling function to execute
 @param interval The interval for polling in milliseconds
 @param expiryFn The function to check if the polling should stop
 @param options Timeout function options
 */

export function useTimeoutPollWithExpiry(
  fn: () => Awaitable<void>,
  interval: MaybeRefOrGetter,
  expiryFn: () => boolean,
  options: UseTimeoutPollOptions = {}
): Pausable {
  const wrappedFn = async () => {
    if (expiryFn()) {
      usePoll.pause();
    } else {
      await fn();
    }
  };

  if (expiryFn()) {
    options = { ...options, immediate: false };
  }

  const usePoll = useTimeoutPoll(wrappedFn, interval, options);

  return {
    ...usePoll,
  };
}
