import { setLocalMessages, t } from '@/i18n';
import { createStoreId } from '@/stores/createStoreId';
import { useSnackbarStore } from '@/stores/useSnackbarStore';
import { defineStore } from 'pinia';

export interface Notification {
  type: 'success' | 'error' | 'info';
  message: string;
  timeout?: number;
  action?: {
    label: string;
    callback: () => void;
  };
}

export function isNotification(value: unknown): value is Notification {
  return (
    typeof value === 'object' &&
    value !== null &&
    'type' in value &&
    ['success', 'error', 'info'].includes((value as Notification).type) &&
    'message' in value
  );
}

await setLocalMessages('notifications');
await setLocalMessages('qa-notifications');
await setLocalMessages('knowledge-bases-notifications');
await setLocalMessages('summary-notifications');
await setLocalMessages('search-notifications');
await setLocalMessages('translation-notifications');
await setLocalMessages('transcription-notifications');
await setLocalMessages('workbook-notifications');
await setLocalMessages('chat-notifications');
await setLocalMessages('generation-notifications');

export const useNotificationStore = defineStore(createStoreId('notifications'), () => {
  const snackbarStore = useSnackbarStore();
  const addNotification = (payload: Notification) => {
    snackbarStore.addSnackbar({
      message: payload.message,
      confirmationButtonText: payload.action ? payload.action.label : t('SNACKBAR.got-it'),
      onConfirmation: payload.action ? payload.action.callback : undefined,
      variant: payload.type,
      soft: true,
    });
  };

  const addInfoNotification = (message: string) => {
    addNotification({
      type: 'info',
      message,
    });
  };
  const addSuccessNotification = (message: string) => {
    addNotification({
      type: 'success',
      message,
    });
  };

  const addErrorNotification = (
    message: string,
    action?: {
      label: string;
      callback: () => void;
    }
  ) => {
    addNotification({
      type: 'error',
      message,
      action,
    });
  };

  return {
    addNotification,
    addErrorNotification,
    addInfoNotification,
    addSuccessNotification,
  };
});
